import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import Img from "gatsby-image";
import { graphql } from "gatsby";

export default function Template({ data }) {
  const { markdownRemark: page } = data;
  return (
    <Layout title={page.fields.title}>
      <Container className={page.fields.classNames}>
        <Row>
          <Col>
            <h1 className="display-4">{page.fields.title}</h1>
            {page.frontmatter.headerImage && (
              <Img
                fluid={page.frontmatter.headerImage.childImageSharp.fluid}
                alt={page.fields.title}
                width="100%"
              />
            )}
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col xs={{span: 12, order: 12}} md={{ span: 9, order: 1}}>
            {page.frontmatter.subHeaderImage && (
              <Img
                fluid={page.frontmatter.subHeaderImage.childImageSharp.fluid}
                alt={page.fields.title}
                width="100%"
              />
            )}
            <Card body className="px-md-4 pb-4">
              <p className="lead">{page.frontmatter.lead}</p>
              <div dangerouslySetInnerHTML={{ __html: page.html }} />
            </Card>
          </Col>
          <Col xs={{span: 12, order: 1}} md={{span: 3, order: 12}} className="d-flex">
            <Nav
              className="flex-column flex-fill py-4"
              activeKey={page.fields.slug}
            >
              {page.fields.siblingLinks &&
                page.fields.siblingLinks
                  .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
                  .map(({ slug, name }) => {
                    return (
                      <Nav.Link key={slug} href={slug}>
                        {name}
                      </Nav.Link>
                    );
                  })}
            </Nav>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const CategorizedPageQuery = graphql`
  query CategorizedPageByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      fields {
        classNames
        title
        slug
        siblingLinks {
          name
          slug
          createdAt
        }
      }
      frontmatter {
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        subHeaderImage {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
